<template>
    <div class="text-left">
            <searchLive></searchLive>
        <div class="text-left newBodyWidth m-b-75">
            <div class="m-t-30">
                <el-carousel :interval="4000" type="card" height="320px">
                    <el-carousel-item v-for="(v,i) in LiveRoomBy" :key="i">
                        <div class="roomByImages"  @click="v.liveRoomId?$oucy.openNewPage('/live?id='+v.liveRoomId):''">
                            <img :src="$oucy.ossUrl+v.roomByImage" alt="" class="roomByImage">
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="df">
                <div class="fl">
                    <div class="title"><img src="@/assets/images/live/just.png" alt=""><span class="m-l-5">正在直播</span></div>
                    <div class="boxs">
                        <div v-for="(v,i) of liveOnlineList" class="boxx" :key="i">
                            <div class="box pointer" @click="gotoLive(v)">
                                <div class="roomIcons">
                                    <img :src="$oucy.ossUrl+v.roomIcon" alt="" class="roomIcon" :class="v.roomPwd?'lock':''">
                                    <liveType :data="v" class="liveType"></liveType>
                                    <div class="locks" v-if="v.roomPwd"><img src="@/assets/images/live/lock.png" alt=""></div>
                                </div>
                                <div class="bottom">
                                    <div class="df-jc-s-b">
                                        <div class="line-1">{{v.roomTitle}}</div>
                                        <div><img src="@/assets/images/live/fire.png" alt=""> {{v.liveRoomPBasic.pwatch||0}}</div>
                                    </div>
                                    <div class="df-jc-s-b df-ai-c m-t-10">
                                        <div class="df-ai-c"><img :src="$oucy.ossUrl+v.ltUserAuthBasic.detailAvatar" v-if="v.ltUserAuthBasic && v.ltUserAuthBasic.detailAvatar" alt="" class="head"><span class="detailNickname m-l-10">{{v.ltUserAuthBasic.detailNickname}}</span></div>
                                        <div class="pInteract">{{v.liveRoomPBasic.ponline||0}}人正在观看</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="fr">
                    <div class="title"><img src="@/assets/images/live/just.png" alt=""><span class="m-l-5">直播预告</span></div>
                    <div class="just">
                        <div v-for="(v,i) of list0" class="justBox pointer" :key="i" @click="gotoLive(v)">
                            <div class="line-1 m-b-10">
                                <span class="m-l-10 time">{{new Date(v.roomTimeBeginStart).format('d')}}日</span>
                                <span class="m-l-10 time">{{new Date(v.roomTimeBeginStart).format('hh:mm:ss')}}</span>
                                <span class="m-l-10">{{v.roomTitle}}</span>
                            </div>
                            <div class="roomIcons"><img :src="$oucy.ossUrl+v.roomIcon" alt="" class="roomIcon" :class="v.roomPwd?'lock':''">
                                <div class="locks" v-if="v.roomPwd"><img src="@/assets/images/live/lock.png" alt=""></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="title"><img src="@/assets/images/live/just.png" alt=""><span class="m-l-5">精彩回放</span></div>
            <div class="boxs box2s">
                <div v-for="(v,i) of list1" class="box2" :key="i">
                    <div class="box22 pointer" @click="gotoLive(v)">
                        <div class="roomIcons">
                            <img :src="$oucy.ossUrl+v.roomIcon" alt="" class="roomIcon" :class="v.roomPwd?'lock':''">
                            <liveType :data="v" class="liveType"></liveType>
                                <div class="locks" v-if="v.roomPwd"><img src="@/assets/images/live/lock.png" alt=""></div>
                        </div>
                        <div class="bottom">
                            <div class="df-jc-s-b">
                                <div class="line-1">{{v.roomTitle}}</div>
                                <div><img src="@/assets/images/live/fire.png" alt=""> {{v.liveRoomPBasic.pwatch||0}}</div>
                            </div>
                            <div class="df-jc-s-b df-ai-c m-t-10">
                                <div class="df-ai-c"><img :src="$oucy.ossUrl+v.ltUserAuthBasic.detailAvatar" v-if="v.ltUserAuthBasic && v.ltUserAuthBasic.detailAvatar" alt="" class="head"><span class="detailNickname m-l-10">{{v.ltUserAuthBasic.detailNickname}}</span></div>
                                <div class="pInteract">{{v.liveRoomPBasic.ponline||0}}人正在观看</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="私密直播" :visible.sync="dialogFormVisible" width="400px">
          <el-form :model="form">
            <el-form-item label="" label-width="0">
              <el-input v-model="form.roomPassword" autocomplete="off" placeholder="请输入房间密码"></el-input>
            </el-form-item>

          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="getVerifyLiveRoomPassword">确 定</el-button>
          </div>
        </el-dialog>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { localSet, localGet, localDel } from "@/store/store";

import { notice, liveroom,liveroomp, icon } from '@/service/index.js'
import emoji from "@/util/emoji.json"
export default {
    name: "liveList",
    data() {
        return {
            list0: [],
            list1: [],
            date: {},
            dateList: [],
            liveOnlineList: [],
            LiveRoomBy: [],
            dialogFormVisible:false,
            form:{
                id:null,
                roomPassword:null
            }
        }
    },
    computed: {

    },
    watch: {},
    mounted() {
        this.getLiveOnlineList()
        this.queryAllLiveRoom()
        this.queryAllLiveRoom1()
        this.queryAllLiveRoomBy()
        this.dateAdd()
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0)
    },
    methods: {

        // 获取直播间正在直播列表
        getLiveOnlineList() {
            liveroom.queryAllLiveRoom({ start: 0, limit: 6, roomAuthStatus: 1,roomStatus:3 }).then(res => {
                this.liveOnlineList = res.content||[]
            })
        },
        queryAllLiveRoom() {
            // liveSituationEnum   直播情况 0直播预告 1 直播回放
            // roomAuthStatus  审核状态：0待审核 1审核通过 2审核驳回
            liveroom.queryAllLiveRoom({ start: 0, limit: 50, roomAuthStatus: 1,liveSituationEnum:0,roomStatus:0 }).then(res => {
                this.list0 = res && res.content||[]
            })
        },
        queryAllLiveRoom1() {
            // liveSituationEnum   直播情况 0直播预告 1 直播回放
            // roomAuthStatus  审核状态：0待审核 1审核通过 2审核驳回
            liveroom.queryAllLiveRoom({ start: 0, limit: 8, roomAuthStatus: 1,liveSituationEnum:1,roomStatus:4 }).then(res => {
                this.list1 = res && res.content||[]
            })
        },
        // 轮播图
        queryAllLiveRoomBy() {
            liveroomp.queryAllLiveRoomBy({ roomByFlag: 0, }).then(res => {
                this.LiveRoomBy = res ||[]
            })
        },
        dateAdd() {
            let date = new Date()
            let dateList = []
            for (var i = 0; i < 6; i++) {
                let d = date.DateAdd('d', i)
                dateList.push({
                    id: i,
                    d: d.format('d'),
                    date: d.format('yyyy-MM-dd'),
                })
            }
            this.date = dateList[0]
            this.dateList = dateList
        },
        gotoLive(v) {
            if(v.roomPwd && this.getManage(v)==false){
                this.form.id=v.id
                this.form.roomPassword=''
                this.dialogFormVisible=true
                return
            }
                this.$oucy.go('/live?id=' + v.id)
        },
        // 获取管理
        getManage(v) {
            // let token = localGet(this.$oucy.tokenKey)
            let userInfo = localGet(this.$oucy.userInfoKey)
            if ( userInfo && v && v.ltUserAuthBasic && v.ltUserAuthBasic.id == userInfo.id) {
                // 是主播
               return true
            } else {
                return false
            }
        },
        getVerifyLiveRoomPassword(){
            if(!this.form.roomPassword){
                    this.$message('请输入密码')
                return
            }
            liveroom.getVerifyLiveRoomPassword(this.form).then(res=>{
                if(res){
                localSet('livePwd',this.form)
                this.$oucy.go('/live?id=' + this.form.id)

                }else{
                    this.$message('密码不正确')
                }

            })
        },
    }
}
</script>
<style scoped lang="less">
/*.el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}*/

.boxs {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}

.box {
    margin: 10px;
    background: #fff;
    border-radius: 8px;

    .roomIcon {
        width: 100%;
    }
}

.boxx {
    width: 33.33%;


}

.title {
    font-size: 24px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #555C68;
    margin-top: 30px;
    margin-bottom: 20px;
}

.roomIcons {
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}

.roomIcon {
    border-radius: 10px;
}

.bottom {
    padding: 30px;
}

.head {
    width: 32px;
    height: 32px;
    border-radius: 32px;
}

.roomTitle {
    font-size: 16px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    line-height: 16px;
    color: #555C68;
}

.detailNickname {
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    line-height: 16px;
    color: #555C68;
}

.pInteract {
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    line-height: 16px;
    color: #AAAFB7;
}

.fl {
    flex: 3;
}

.fr {
    flex: 1;
    margin-left: 10px;
    margin-top: 10px;
}

.just {
    height: 707px;
    overflow: auto;
    color: #555C68;
    font-size: 16px;
    padding: 20px;
    box-sizing: border-box;
    background: #fff;

    .time {
        font-size: 14px;
        color: #868C94;
    }

    .roomIcon {
        width: 100%;
    }

    .justBox {
        margin-bottom: 20px;
    }
}

.box2s {
    display: flex;
    margin-left: -10px;
    margin-right: -10px;
}

.box2 {
    width: 25%;
}

.box22 {
    background: #fff;
    border-radius: 8px;
    margin: 10px;

    .roomIcon {
        width: 100%;
    }
}

.listBox {}

.liveType {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.locks{
    display: flex;
    justify-content: center;
    align-items: center;
     position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
/*background: rgba(250, 250, 250, 0.9); */
}
.roomIcon.lock{
    filter: blur(10px);
}
.roomByImages{
    border-radius: 10px;
    overflow: hidden;
    font-size: 0;
}
.roomByImage{
    height: 320px;
    min-width: 100%;
}
</style>